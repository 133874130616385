(function() {
  const DonutChartBuilder = {};

  DonutChartBuilder.build = function(elm, data, companies_number) {
    const w = $(elm).width();
    const h = $(elm).height();
    const r = Math.min(w, h) / 3.6;
    const labelr = r + 45; // radius for label anchor
    const color = d3.scale.ordinal().range(["#6D973E", "#EBC403", "#EB5945"]);
    const donut = d3.layout.pie();
    const arc = d3.svg.arc().innerRadius(r * 0.8).outerRadius(r).padAngle(0.03);

    const vis = d3.select(elm)
      .append("svg:svg")
      .data([data])
      .attr("width", w)
      .attr("height", h);

    const arcs = vis.selectAll("g.arc")
      .data(donut.value(function(d) { return d.number; }))
      .enter().append("svg:g")
      .attr("class", "arc")
      .attr("transform", "translate(" + w / 2 + "," + h / 2 + ")");

    // .attr("fill", function(d, i) { return color(d.data.number); })
    arcs.append("svg:path")
      .attr("class", function(d, i) { return d.data.color; })
      .attr("d", arc);

    // .style("fill", function(d, i) { return color(d.data.number); })
    arcs.append("svg:text")
      .attr("transform", function(d) {
        const c = arc.centroid(d);
        const x = c[0];
        const y = c[1];
        // pythagorean theorem for hypotenuse
        const h = Math.sqrt(x * x + y * y);
        return "translate(" + (x / h * labelr) + "," +
                 (y / h * labelr) + ")";
      })
      .attr("dy", ".35em")
      .attr("class", "percentage")
      .attr("text-anchor", function(d) {
        // are we past the center?
        return (d.endAngle + d.startAngle) / 2 > Math.PI
          ? "end"
          : "start";
      })
      .attr("class", function(d, i) { return d.data.color; })
      .text(function(d, i) { return d.data.text; });

    vis.append("text")
      .attr("class", "num")
      .attr("transform", "translate(" + w / 2 + "," + h / 2 + ")")
      .text(companies_number);

    vis.append("text")
      .attr("class", "companies")
      .attr("transform", "translate(" + w / 2 + "," + (h / 2 + 30) + ")")
      .text("Companies");
  };

  window.ChartBuilders = (window.ChartBuilders || {});
  window.ChartBuilders.DonutChartBuilder = DonutChartBuilder;
})();
